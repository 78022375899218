import React, { useContext } from 'react';
import AttributeCard from './AttributeCard';
import styled from 'styled-components';
import ToolCard from './ToolCard.jsx';
import { fetchFavorites } from '../../backend/favorites.js';
import { ErrorModalContext } from '../../Modals/ErrorModal.jsx';
import { useQuery } from 'react-query';
import { fetchTool } from '../../backend/tooldetails.js';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F2F7F9;
  width: 100vw;
  padding: 10px 0;
`

const ToolComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 1225px;
  width: 100%;
  border-radius: 10px;
  padding-bottom: 12.2px;
`;

const ToolComparisonContainerContent = styled.div`
  display: flex;
  align-items: start;
  gap: 19.4px;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 12.2px;

  @media (max-width: 1024px) {
    /* Tablet view */
    justify-content: center; /* Center cards */
    flex-direction: row;
    gap: 20px;
    padding: 0;
    padding-top: 15px;

    & > * {
      flex: 0 1 calc(50% - 20px); /* 2 columns */
    }
  }

  @media (max-width: 768px) {
    /* Mobile view */
    justify-content: center; /* Center cards */
    align-items: center;
    flex-direction: column; /* Stack in single column */
    gap: 20px;
    padding: 15px;

    & > * {
      flex: 1 1 100%; /* Full width for single column */
    }
  }
`;

const Title = styled.h1`
  margin: 20px 21px;
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3a4d;
`;

const index = () => {
  const [, setError] = useContext(ErrorModalContext);
  const params = new URLSearchParams(window.location.search);
  const toolsArr = JSON.parse(`[${params.get('tools')}]`);

  const { data: favoritesData, refetch } = useQuery(
    'fetchFavorites',
    fetchFavorites,
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] !== '401') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FA-1-${errorCode[1]}`,
          });
        }
      },
    }
  );

  const referenceToolId = toolsArr[0];

  const { data, isLoading, error } = useQuery(`tool-${referenceToolId}`, () => fetchTool(referenceToolId), {
    onError: (error) => {
      const errorCode = error.message.split('/');
      setError(
        error.constructor.name === 'TypeError'
          ? {
              title: 'Looks like something went wrong',
              message:
                'Network connection lost. Please check your network connection and try again.',
            }
          : {
              title: 'Looks like something went wrong',
              message:
                'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
              errorCode: `#TO-1-${errorCode[1]}`,
            }
      );
    },
  });

  return (
    <Container>
      {error && <h1>Tool Not Found</h1>}
      {isLoading? <h1>Loading...</h1> 
      : <ToolComparisonContainer>
          <Title>Tool Comparison</Title>
          <ToolComparisonContainerContent>
            <AttributeCard tool={data} />
            {toolsArr.map((toolId) => (
              <ToolCard 
                key={toolId} 
                referenceTool={data} 
                toolId={toolId}
                isFavorited={favoritesData?.some(
                  (favoritedItem) =>
                    favoritedItem.SapNumber == toolId
                )}
                refetchFavoritesData={refetch} 
              />
            ))}
          </ToolComparisonContainerContent>
        </ToolComparisonContainer>  
      }
    </Container>
  );
};

export default index;
