import React, { useContext } from 'react';
import styled from 'styled-components';
import FavoritesToggleButton from '../Favorites/FavoritesToggleButton';
import UpdateItemInCartForm from '../ToolDetailsPage/UpdateItemInCartForm';
import { useLocation } from 'react-router';
import { fetchTool } from '../../backend/tooldetails';
import { useQuery } from 'react-query';
import { ErrorModalContext } from '../../Modals/ErrorModal';

const AttributesList = styled.ul`
  font-family: Univers;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  list-style: none;
  width: 315px;
  min-height: 370px;
  font-size: 18px;
  border-radius: 12px;
  border: 1px solid #000000;
  padding-inline-start: 0; /* For Firefox and others */
  padding-left: 0; /* For Chrome */
  margin: 0;
  overflow: hidden;
`;

const OddListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 90%;
  height: 27px;
  background: #ffffff;
  color: #2d3a4d;
  border-bottom: 1px solid #000000;
  font-size: 16px;
  padding: 0 5px 0 25px;
  background-color: ${(props) => (props.isDifferent ? 'yellow' : '#ffffff')};

  @media (max-width: 1024px) {
    /* Tablet view */
    width: 100%;
  }
`;

const EvenListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 90%;
  height: 27px;
  background: #e4e9ed;
  color: #2d3a4d;
  border-bottom: 1px solid #000000;
  font-size: 16px;
  padding: 0 5px 0 25px;
  background-color: ${(props) => (props.isDifferent ? 'yellow' : '#e4e9ed')};

  @media (max-width: 1024px) {
    /* Tablet view */
    width: 100%;
  }
`;

const Heading = styled.li`
  background: #2d394e;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  border-radius: 10px 10px 0 0;
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
`;

const Actions = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 0 0 10px 10px;
  height: 75px;
  width: 100%;
  gap: 8px;

  a {
    color: #00a0a5;
    text-decoration: none;
    font-size: 12px;
  }

  #add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    width: 107px;
    color: #fff;
    background-color: #df0000;
    border-radius: 16px;
  }
`;

const EllipsisOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FavoritesToggleButtonCustom = styled(FavoritesToggleButton)`
  width: auto;
  margin: 0;
  padding: 0;
`;

const ToolCard = ({ toolId, referenceTool, isFavorited, refetchFavoritesData }) => {
  const [, setError] = useContext(ErrorModalContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const fullPath = currentPath + location.search;

  const { data, isLoading, error } = useQuery(`tool-${toolId}`, () => fetchTool(toolId), {
    onError: (error) => {
      const errorCode = error.message.split('/');
      setError(
        error.constructor.name === 'TypeError'
          ? {
              title: 'Looks like something went wrong',
              message:
                'Network connection lost. Please check your network connection and try again.',
            }
          : {
              title: 'Looks like something went wrong',
              message:
                'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
              errorCode: `#TO-1-${errorCode[1]}`,
            }
      );
    },
  });

  return (
    <AttributesList>
      <Heading>{data?.Name}</Heading>
      {error && <div>Tool Not Found</div>}
      {!isLoading &&
        data?.DescriptionLite.slice(0, 15).map((attr, index) => {
          if (referenceTool.DescriptionLite[index] === undefined) {
            return null;
          }
          // Check if the attribute is different from the reference tool
          const isDifferent = referenceTool
            ? attr.Value != referenceTool.DescriptionLite[index].Value
            : false;

          // Use different list item styles for even/odd rows
          return index % 2 !== 0 ? (
            <EvenListItem key={index} isDifferent={isDifferent}>
              <EllipsisOverflow>
                {attr.Value ? attr.Value : 'N/A'}
              </EllipsisOverflow>
            </EvenListItem>
          ) : (
            <OddListItem key={index} isDifferent={isDifferent}>
              <EllipsisOverflow>
                {attr.Value ? attr.Value : 'N/A'}
              </EllipsisOverflow>
            </OddListItem>
          );
        })
      }
      {!isLoading && 
        <Actions>
            <FavoritesToggleButtonCustom
              pagePath={fullPath}
              sapNumber={data?.SapNumber}
              isFavorited={isFavorited}
              refetchFavoritesData={refetchFavoritesData}
            />
            <UpdateItemInCartForm
              toolData={data}
              oktaRedirectOnLoginTo={`/tool/${data?.SapNumber}`}
            />
        </Actions>
      }
    </AttributesList>
  );
};

export default ToolCard;
