import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import SearchContext from '../SearchContext';
import Breadcrumb from '../../../shared/Breadcrumb';
import { Sliders, Tool } from 'react-feather';

const Container = styled.div`
  padding-bottom: 30px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const SearchParagraph = styled.p`
  font-family: Univers;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 2px;
  color: var(--color-gray1);
  + * {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

const OptionButton = styled.button`
  font-family: Univers;
  font-size: 16px;
  cursor: pointer;
  text-decoration: ${(props) => (props.$selected ? 'none' : 'underline')};
  color: ${(props) => (props.$selected ? 'var(--color-gray1)' : '#cc0000')};
  background-color: transparent;
  border: none;
  width: 0px;
  margin-right: 10px;
  @supports (-moz-appearance: none) {
    margin-right: 15px;
  }
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  margin-top: 12px;
  margin-left: 10px;
`;

const SplideContainer = styled(Splide)`
  height: 42px;
  width: 90%;

  padding-left: 4%;
  padding-right: 4%;

  .splide__arrow {
    background: none;
    height: 20px;
    width: 20px;
  }

  .splide__arrow--prev {
    background: var(--color-primary);
    margin-right: 20px;

    right: 88%;
    left: 0;
  }

  .splide__arrow--next {
    background: var(--color-primary);
    right: 0;
  }

  .splide__arrow svg {
    fill: #fff;
    height: 0.8em;
    width: 0.8em;
  }
`;

const SlideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  justify-content: center;
`;

const SlideLinkedButton = styled.div`
  background: none;
  border: none;
  height: 100px;
  display: flex;
  max-width: 80%;
  min-width: 100px;
  align-items: center;
  font-family: Univers;
  font-size: 14px;
  text-align: left;
  color: var(--color-primary);
  cursor: pointer;
`;

const CompareButton = styled.button`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: fit-content;
  font-family: Univers;
  font-size: 14px;
  background-color: var(--color-gray1);
  color: white;
  border: unset;
  padding: 5px;
  border-radius: 8px;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-bottom-color 0.2s ease-out;
  cursor: ${(props) => (props.$isSelectionMade ? 'pointer' : '')};
  &:hover {
    cursor: pointer;
    background-color: var(--color-gray1);
  }
`;

const ButtonText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CancelButton = styled.button`
  display: flex;
  position: absolute;
  right: 8.5rem;
  bottom: 0.3rem;
  color: #f00;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
`;

const StyledSlider = styled(Sliders)`
  display: inline-block;
  cursor: pointer;
  margin-right: 8px;
  margin-left: 2px;
  padding-top: 2px;

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchResultsHeader = ({
  searchTerm,
  urlRange,
  rangeStart,
  rangeEnd,
  tools,
  canCompare,
  toolComparison,
  enableToolComparison,
}) => {
  const [selectedOption, setSelectedOption] = useState(parseInt(urlRange));
  const { sumOfResults, onRangeFilterChange, browseOptions } =
    useContext(SearchContext);

  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onRangeFilterChange(option);
  };

  const topLevelParam = decodeURIComponent(window.location.search);
  const params = new URLSearchParams(topLevelParam.split('?')[1]);
  const browseParams = params.get('browseOptions');
  const browseOptionsArray = browseParams?.split(',');

  const handleBreadcrumbClick = (clickedBreadcrumb) => {
    const clickedIndex = browseOptionsArray.indexOf(clickedBreadcrumb);

    if (clickedIndex === -1) {
      return;
    }
    const elementsBeforeClicked = browseOptionsArray.slice(0, clickedIndex + 1);

    const newUrl = `/browse?${elementsBeforeClicked.join('&')}`;

    navigate(newUrl);
  };

  const resultRangeStart = rangeStart;
  const resultRangeEnd = rangeEnd; // skip + take

  const splideOptions = {
    type: false,
    heightRatio: 0.5,
    arrows: true,
    perPage: window.innerWidth < 400 ? 1 : 'auto',
    pagination: false,
  };

  const navigateToToolComparison = () => {
    console.log('navigating to tool comparison');
    const toolsURI = encodeURIComponent(tools.toString());

    if (!toolComparison) {
      enableToolComparison(true);
    } else if (canCompare) {
      navigate('/search/tool-comparison?tools=' + toolsURI);
    }
  };

  return (
    <Container>
      <FlexContainer>
        <div>
          <SearchParagraph>Search Results</SearchParagraph>
          {browseOptions.length !== 0 && (
            <>
              {window.innerWidth > 400 ? (
                <BreadcrumbContainer>
                  {browseOptionsArray.map((breadcrumb, index) => (
                    <Breadcrumb
                      key={index}
                      text={breadcrumb}
                      lastOfType={browseOptionsArray.length - 1 === index}
                      onClick={
                        browseOptionsArray.length - 1 !== index
                          ? () => handleBreadcrumbClick(breadcrumb)
                          : null
                      }
                    />
                  ))}
                </BreadcrumbContainer>
              ) : (
                <SplideContainer options={splideOptions}>
                  {browseOptionsArray.map((path, index) => (
                    <SplideSlide key={index}>
                      <SlideContainer>
                        <SlideLinkedButton
                          onClick={
                            index === browseOptionsArray.length - 1
                              ? null
                              : () => handleBreadcrumbClick(path)
                          }
                        >
                          <ButtonText> {path} </ButtonText>
                        </SlideLinkedButton>
                      </SlideContainer>
                    </SplideSlide>
                  ))}
                </SplideContainer>
              )}
            </>
          )}

          <p>
            Showing
            {` ${sumOfResults > 0 ? resultRangeStart : 0}-${
              sumOfResults > 0 ? Math.min(resultRangeEnd, sumOfResults) : 0
            } `}{' '}
            of {sumOfResults ? sumOfResults : 0} results{' '}
            {searchTerm && searchTerm != '' ? `for "${searchTerm}"` : ''}
          </p>
          {sumOfResults !== 0 &&
            sumOfResults !== undefined &&
            sumOfResults > 10 && (
              <div>
                Results per page:
                <OptionButton
                  $selected={selectedOption === 10}
                  onClick={() => handleOptionClick(10)}
                >
                  10
                </OptionButton>
                <OptionButton
                  $selected={selectedOption === 25}
                  onClick={() => handleOptionClick(25)}
                >
                  25
                </OptionButton>
                <OptionButton
                  $selected={selectedOption === 50}
                  onClick={() => handleOptionClick(50)}
                >
                  50
                </OptionButton>
              </div>
            )}
        </div>
        <RelativeContainer>
          {/* TODO: find the correct Material Symbols Icon and add cancel button state + styling */}
          {toolComparison && (
            <CancelButton onClick={() => enableToolComparison(false)}>
              Cancel
            </CancelButton>
          )}
          <CompareButton
            disabled={!canCompare}
            style={{
              opacity: canCompare ? 1 : 0.5,
              pointerEvents: canCompare ? 'auto' : 'none',
              background: canCompare ? '#525e70' : '#4EAD5B',
            }}
            onClick={() => navigateToToolComparison()}
          >
            <StyledSlider size={16} strokeWidth={1.5} />
            <ButtonText>Compare Tools</ButtonText>
          </CompareButton>
        </RelativeContainer>
      </FlexContainer>
    </Container>
  );
};

export default SearchResultsHeader;
