import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import DidYouKnowSection from './DidYouKnowSection.jsx';
import ArticlesCarousel from './Carousels/ArticlesCarousel.jsx';
import CallToActionCarousel from './Carousels/CallToActionCarousel.jsx';
import ToolsCarousel from './Carousels/ToolsCarousel.jsx';
import InformationBar from './InformationBar.jsx';
import { appInsights } from '../../appInsights.js';

import ApplicationContext from '../../ApplicationContext.js';

const ContentContainer = styled.div`
  box-sizing: border-box;
  padding-left: 112px;
  padding-right: 112px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 0;
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
  }
`;

const LandingPage = () => {
  const navigate = useNavigate();
  const { classesIsLoading, classesLoadingError } =
    useContext(ApplicationContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const tooldId = searchParams.get('tool');
    if (tooldId) {
      navigate(`/tool/${tooldId}`);
    }
  }, [searchParams.get('tool')]);

  useEffect(() => {
    document.title = 'Product and Service Catalog';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content =
      'Halliburton product and service catalog home page';

    document.head.appendChild(metaDescription);

    return () => {
      document.title = '';
      document.head.removeChild(metaDescription);
    };
  }, []);

  useEffect(() => {
    const trackViewChange = () => {
      appInsights.trackPageView({ name: location.pathname });
    };
    trackViewChange();
  }, [location.pathname]);

  let cachedClasses = JSON.parse(localStorage.getItem('fetchedClasses'));

  const getClasses = (classToFilter) => {
    const options = cachedClasses
      ?.filter(
        (option) =>
          option.ClassPath !== null &&
          option.ClassPath !== '' &&
          option.Name !== 'Layout Drawing - General'
      )
      .map((option) => {
        const classes = option?.ClassPath?.split('|');

        const classIndex = classes?.indexOf(classToFilter);
        let classToRender = '';

        if (classIndex !== -1 && classIndex < classes?.length - 1) {
          const classAfterMain = classes[classIndex + 1];
          classToRender = classAfterMain;
        } else {
          return null;
        }

        return {
          className: classToRender,
        };
      });

    const filteredOptions = options?.filter(
      (option) =>
        option !== null &&
        option.className !== 'Tesat01' &&
        option.className !== 'Mechanical Kit' &&
        option.className !== 'Inactive Classes'
    );

    const uniqueClassesSet = new Set(
      filteredOptions?.map((toolClass) => toolClass.className)
    );

    const classes = [...uniqueClassesSet].map((toolClass) =>
      filteredOptions?.find((className) => toolClass === className.className)
    );

    return classes;
  };

  return (
    <>
      <CallToActionCarousel />
      <ArticlesCarousel />
      <ContentContainer>
        <ToolsCarousel
          carouselName={'Downhole Tools'}
          productName={getClasses('Downhole Tools')}
          loading={classesIsLoading}
          loadingError={!!classesLoadingError}
        />
        <ToolsCarousel
          carouselName={'Wellbore Cleaning Technology'}
          productName={getClasses('Wellbore Cleaning Technology')}
          loading={classesIsLoading}
          loadingError={!!classesLoadingError}
          grayBackground
        />
        <InformationBar />
        <ToolsCarousel
          carouselName={'Casing Cleaning Tools '}
          productName={getClasses('Casing Cleaning Tools')}
          loading={classesIsLoading}
          loadingError={!!classesLoadingError}
        />
        <DidYouKnowSection />
      </ContentContainer>
    </>
  );
};

export default LandingPage;
